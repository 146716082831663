export class CerumXState {
    static isCerumX() {
        let win = window;
        do {
            try {
                const cerumx = win.document.querySelectorAll('meta[name=cerumx]').item(0);
                if (cerumx) {
                    return Boolean(cerumx.getAttribute('content'));
                }
            }
            catch (_error) {
                /** void  */
            }
            try {
                if (win.parent && win.parent !== win) {
                    win = win.parent;
                }
                else if (win.opener && win.opener !== win) {
                    win = win.opener;
                }
                else {
                    win = null;
                }
            }
            catch (_error) {
                win = null;
            }
        } while (win);
        return false;
    }
}
