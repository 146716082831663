/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { MembershipCategoryJson } from '../../../schemas/MembershipCategoryJson';
import { SignupCheckoutStatusResponse } from '../../../schemas/SignupCheckoutStatusResponse';
import { SignupInitiateCheckoutResponse } from '../../../schemas/SignupInitiateCheckoutResponse';
import { SignupInitiatePaymentResponse } from '../../../schemas/SignupInitiatePaymentResponse';
import { SignupPaymentStatusResponse } from '../../../schemas/SignupPaymentStatusResponse';
import { ew1881Response } from '../../../schemas/ew1881Response';
import { ewExerciseMarketingTracking_JSON } from '../../../schemas/ewExerciseMarketingTracking_JSON';
import { ewExercisePortalInfo_JSON } from '../../../schemas/ewExercisePortalInfo_JSON';
import { ewJSONContact } from '../../../schemas/ewJSONContact';
import { ewJSONDepartment } from '../../../schemas/ewJSONDepartment';
import { ewJSONExerciseClass } from '../../../schemas/ewJSONExerciseClass';
import { ewJSONOnlineArticle } from '../../../schemas/ewJSONOnlineArticle';
import { ewJSONPortal } from '../../../schemas/ewJSONPortal';
import { ewJSONPortalColor } from '../../../schemas/ewJSONPortalColor';
import { ewJSONSponsorSetup } from '../../../schemas/ewJSONSponsorSetup';
import { ewMemberContractResult } from '../../../schemas/ewMemberContractResult';
import { ewNewMemberInfoResult } from '../../../schemas/ewNewMemberInfoResult';
import { ewNewProfileInfoResult } from '../../../schemas/ewNewProfileInfoResult';
import { ewNewSponsorInfoResult } from '../../../schemas/ewNewSponsorInfoResult';
import { ewRestHandlerV1BoostSignupInvoice } from '../../../schemas/ewRestHandlerV1BoostSignupInvoice';
import { ewRestV1BoostSignupConditionHandler_Response } from '../../../schemas/ewRestV1BoostSignupConditionHandler_Response';
import { ewSponsedInfo } from '../../../schemas/ewSponsedInfo';
import { ewSponsorConnectionResponse } from '../../../schemas/ewSponsorConnectionResponse';
import { ewUpdateMemberInfoResult } from '../../../schemas/ewUpdateMemberInfoResult';
import { exPostalcode } from '../../../schemas/exPostalcode';
export class MembersignupAPI extends APIRestClient {
    /**
     * Registrer nytt medlem
     * Implementert hovedsakelig i *ewRestAvtalegiroHandler*::*read*
     */
    static getAvtalegiroCallback(__QUERY) {
        return this._get('/avtalegiro/callback', {}, __QUERY, null, APIRestClient.Native.string(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Hent ut informasjon om et senter
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getCenter*
     */
    static getV1BoostsignupCenter() {
        return this._get('/v1/boostsignup/center', {}, null, null, ewJSONPortal.classOf(ewJSONPortal, new ewJSONPortal()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Hent timer
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getClasses*
     */
    static getV1BoostsignupClasses(__QUERY) {
        return this._get('/v1/boostsignup/classes', {}, __QUERY, null, ewJSONExerciseClass.classOf(ewJSONExerciseClass, new ewJSONExerciseClass()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Hent ut fargepalett for et senter
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getColors*
     */
    static getV1BoostsignupColors(__QUERY) {
        return this._get('/v1/boostsignup/colors', {}, __QUERY, null, ewJSONPortalColor.classOf(ewJSONPortalColor, new ewJSONPortalColor()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer avdelinger
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getDepartments*
     */
    static getV1BoostsignupDepartments() {
        return this._get('/v1/boostsignup/departments', {}, null, null, ewJSONDepartment.classOf(ewJSONDepartment, new ewJSONDepartment()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Hent medlemsskapstyper for avdeling
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getMembershipGroups*
     */
    static getV1BoostsignupDepartmentsMembershipsGroups() {
        return this._get('/v1/boostsignup/departments/memberships/groups', {}, null, null, MembershipCategoryJson.classOf(MembershipCategoryJson, new MembershipCategoryJson()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Hent medlemsskap for avdeling
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getMemberships*
     */
    static getV1BoostsignupDepartmentsMembershipsUsingDepartmentid(departmentid, __QUERY) {
        return this._get('/v1/boostsignup/departments/{departmentid}/memberships', { departmentid }, __QUERY, null, ewJSONOnlineArticle.classOf(ewJSONOnlineArticle, new ewJSONOnlineArticle()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Hent ut bilder som kan vises for en avdeling
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getImages*
     */
    static getV1BoostsignupImages(__QUERY) {
        return this._get('/v1/boostsignup/images', {}, __QUERY, null, APIRestClient.Native.string().arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Hent faktura
     * Implementert hovedsakelig i *ewRestV1BoostSignupInvoiceHandler*::*getInvoice*
     */
    static getV1BoostsignupInvoiceUsingInvoiceId(invoice_id) {
        return this._get('/v1/boostsignup/invoice/{invoice_id}', { invoice_id }, null, null, ewRestHandlerV1BoostSignupInvoice.classOf(ewRestHandlerV1BoostSignupInvoice, new ewRestHandlerV1BoostSignupInvoice()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Logo for senteret
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getLogo*
     */
    static getV1BoostsignupLogo(__QUERY) {
        return this._get('/v1/boostsignup/logo', {}, __QUERY, null, APIRestClient.Native.Blob(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'blob');
    }
    /**
     * Hent poststed
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*lookupArea*
     */
    static getV1BoostsignupLookupMunicipalityUsingAreacode(areacode) {
        return this._get('/v1/boostsignup/lookup/municipality/{areacode}', { areacode }, null, null, exPostalcode.classOf(exPostalcode, new exPostalcode()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Oppslag mot leverandoer for aa hente adresse
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*lookupPhone*
     */
    static getV1BoostsignupLookupPhoneUsingPhonenumber(phonenumber) {
        return this._get('/v1/boostsignup/lookup/phone/{phonenumber}', { phonenumber }, null, null, ew1881Response.classOf(ew1881Response, new ew1881Response()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Oppslag mot kunder for aa finne sponsor
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*lookupSponsor*
     */
    static getV1BoostsignupLookupSponsorUsingPhonenumber(phonenumber, __QUERY) {
        return this._get('/v1/boostsignup/lookup/sponsor/{phonenumber}', { phonenumber }, __QUERY, null, APIRestClient.Native.string(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Hent sporingskoder for bruk i html
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getMarketingTracking*
     */
    static getV1BoostsignupMarketingtracking() {
        return this._get('/v1/boostsignup/marketingtracking', {}, null, null, ewExerciseMarketingTracking_JSON.classOf(ewExerciseMarketingTracking_JSON, new ewExerciseMarketingTracking_JSON()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Get Payment Conditions For Center
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getConditions*
     */
    static getV1BoostsignupPaymentConditions() {
        return this._get('/v1/boostsignup/payment/conditions', {}, null, null, APIRestClient.Native.Blob(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'blob');
    }
    /**
     * Get payment Condition url for Center
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getConditionUrl*
     */
    static getV1BoostsignupPaymentConditionurl() {
        return this._get('/v1/boostsignup/payment/conditionurl', {}, null, null, ewRestV1BoostSignupConditionHandler_Response.classOf(ewRestV1BoostSignupConditionHandler_Response, new ewRestV1BoostSignupConditionHandler_Response()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * get payment status
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getPaymentStatus*
     */
    static getV1BoostsignupPaymentUsingInvoiceid(invoiceId) {
        return this._get('/v1/boostsignup/payment/{invoiceId}', { invoiceId }, null, null, SignupPaymentStatusResponse.classOf(SignupPaymentStatusResponse, new SignupPaymentStatusResponse()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Hent velkomstinformasjon for portal
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getPortalInfo*
     */
    static getV1BoostsignupPortalinfo(__QUERY) {
        return this._get('/v1/boostsignup/portalinfo', {}, __QUERY, null, ewExercisePortalInfo_JSON.classOf(ewExercisePortalInfo_JSON, new ewExercisePortalInfo_JSON()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * brukes til aa hente ut info om sponset bruker fra hash
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*getSponsed*
     */
    static getV1BoostsignupSponsed(__QUERY) {
        return this._get('/v1/boostsignup/sponsed', {}, __QUERY, null, ewSponsedInfo.classOf(ewSponsedInfo, new ewSponsedInfo()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Verifiser sponsorkode
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*checkCode*
     */
    static getV1BoostsignupSponsorUsingSponsorcode(sponsorcode, __QUERY) {
        return this._get('/v1/boostsignup/sponsor/{sponsorcode}', { sponsorcode }, __QUERY, null, ewJSONContact.classOf(ewJSONContact, new ewJSONContact()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Sjekke sponsor oppsett for senter
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*sponsorSetup*
     * bpRestRequestJsonResponse<ewJSONSponsorSetup>
     */
    static getV1BoostsignupSponsorsetup() {
        return this._get('/v1/boostsignup/sponsorsetup', {}, null, null, ewJSONSponsorSetup.classOf(ewJSONSponsorSetup, new ewJSONSponsorSetup()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * get payment status
     * Implementert hovedsakelig i *ewRestV2BoostSignupHandler*::*getPaymentStatus*
     */
    static getV2BoostsignupPaymentUsingInvoiceid(invoiceId) {
        return this._get('/v2/boostsignup/payment/{invoiceId}', { invoiceId }, null, null, SignupCheckoutStatusResponse.classOf(SignupCheckoutStatusResponse, new SignupCheckoutStatusResponse()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * initiate payment for invoice
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*initiatepayment*
     */
    static postV1BoostsignupInitiatepayment(__DATA) {
        return this._post('/v1/boostsignup/initiatepayment', {}, null, JSON.stringify(__DATA), SignupInitiatePaymentResponse.classOf(SignupInitiatePaymentResponse, new SignupInitiatePaymentResponse()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Registrer nytt medlem
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*registerMember*
     */
    static postV1BoostsignupMember(__DATA) {
        return this._post('/v1/boostsignup/member', {}, null, JSON.stringify(__DATA), ewNewMemberInfoResult.classOf(ewNewMemberInfoResult, new ewNewMemberInfoResult()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Opprett kontrakt
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*registerContract*
     */
    static postV1BoostsignupMemberContractUsingMemberId(member_id, __DATA) {
        return this._post('/v1/boostsignup/member/{member_id}/contract', { member_id }, null, JSON.stringify(__DATA), ewMemberContractResult.classOf(ewMemberContractResult, new ewMemberContractResult()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Registrer ny profil
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*registerProfile*
     */
    static postV1BoostsignupProfile(__DATA) {
        return this._post('/v1/boostsignup/profile', {}, null, JSON.stringify(__DATA), ewNewProfileInfoResult.classOf(ewNewProfileInfoResult, new ewNewProfileInfoResult()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Registrer ny sponsor
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*registerSponsor*
     */
    static postV1BoostsignupSponsor(__DATA) {
        return this._post('/v1/boostsignup/sponsor', {}, null, JSON.stringify(__DATA), ewNewSponsorInfoResult.classOf(ewNewSponsorInfoResult, new ewNewSponsorInfoResult()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Verifisere sponsor og oppdatere medlem
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*verifySponsor*
     */
    static postV1BoostsignupSponsorUsingSponsorcode(sponsorcode, __DATA) {
        return this._post('/v1/boostsignup/sponsor/{sponsorcode}', { sponsorcode }, null, JSON.stringify(__DATA), APIRestClient.Native.string().hashMapOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Koble sponsor til medlem
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*createSponsorConnection*
     */
    static postV1BoostsignupSponsor_connection(__QUERY) {
        return this._post('/v1/boostsignup/sponsor_connection', {}, __QUERY, null, ewSponsorConnectionResponse.classOf(ewSponsorConnectionResponse, new ewSponsorConnectionResponse()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * initiate payment for invoice
     * Implementert hovedsakelig i *ewRestV2BoostSignupHandler*::*initiatepayment*
     */
    static postV2BoostsignupInitiatepayment(__DATA) {
        return this._post('/v2/boostsignup/initiatepayment', {}, null, JSON.stringify(__DATA), SignupInitiateCheckoutResponse.classOf(SignupInitiateCheckoutResponse, new SignupInitiateCheckoutResponse()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Oppdater eksisterende medlem
     * Implementert hovedsakelig i *ewRestV1BoostSignupHandler*::*updateMember*
     */
    static putV1BoostsignupMemberUsingMemberId(member_id, __DATA) {
        return this._put('/v1/boostsignup/member/{member_id}', { member_id }, null, JSON.stringify(__DATA), ewUpdateMemberInfoResult.classOf(ewUpdateMemberInfoResult, new ewUpdateMemberInfoResult()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
}
