var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import '@polymer/paper-button/paper-button';
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
let BoostButton = class BoostButton extends LitElement {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.raised = false;
        this.toggles = false;
        this.isMouseOverActive = false;
    }
    static get is() {
        return 'boost-button';
    }
    static get styles() {
        return css `
            :host([width-full]) paper-button {
                margin: 10px 0;
                width: 100%;
            }

            :host paper-button {
                margin: var(--cerum-legacy-button-margin, 0 0.29em);
                width: var(--cerum-legacy-button-width);
                text-transform: none;
            }

            :host([disabled]) paper-button {
                box-shadow: none;
            }

            iron-icon {
                height: var(--iron-icon-height, 12);
                width: var(--iron-icon-width, 12px);
                margin: 0 10px;
            }

            paper-button {
                background-color: #ccc !important;
                border: 1px solid #000;
                color: black;
                font-size: 90%;
                letter-spacing: 0.4px;
            }

            paper-button iron-icon {
                stroke: black;
            }

            paper-button.neutral {
                background-color: transparent !important;
                color: black;
                border: 1px solid #000;
            }

            paper-button.neutral iron-icon {
                stroke: black;
            }

            paper-button.primary {
                background-color: var(--portalButtonPrimaryBackground, hsl(211.2, 13%, 90%)) !important;
                color: var(--portalButtonPrimaryForeground, hsl(211.2, 13%, 20%));
                border: 1px solid var(--portalButtonPrimaryForeground, hsl(211.2, 13%, 20%));
            }

            paper-button.header-button {
                background-color: var(--portalButtonPrimaryBackground, #9bb7d2) !important;
                border: none;
                box-shadow: none;
                color: var(--portalButtonPrimaryForeground, #004765) !important;
            }

            paper-button.header-button:hover {
                background-color: var(--portalButtonPrimaryHighlightBackground, #5e7c99) !important;
                color: var(--portalButtonPrimaryForeground, #fff) !important;
            }

            paper-button.submit {
                border: none;
                width: 100%;
                background-color: var(--portalButtonPrimaryBackground, #9bb7d2) !important;
                color: #01344a;
                display: flex;
                justify-content: center;
                padding: 8px 0px 8px 0px;
                border-radius: 6px;
                cursor: pointer;
            }

            paper-button.submit[disabled] {
                opacity: 0.3;
                cursor: auto;
            }

            paper-button.submit:not([disabled]):hover {
                background-color: var(--portalButtonPrimaryHighlightBackground, #5e7c99) !important;
                color: #fff;
            }

            paper-button.logout {
                background-color: #facfcc !important;
                border: none;
                box-shadow: none;
                color: #d61010;
            }
            paper-button.logout:hover {
                background-color: #e5baba !important;
                color: #d61010;
            }

            paper-button.primary-inverted,
            paper-button.primary:hover {
                background-color: var(
                    --portalButtonPrimaryHighlightBackground,
                    hsl(211.2, 13%, 20%)
                ) !important;
                color: var(--portalButtonPrimaryHighlightForeground, hsl(211.2, 13%, 90%)) !important;
            }

            paper-button.primary iron-icon {
                stroke: white;
            }

            paper-button.info {
                background-color: var(--portalButtonInfoBackground, hsl(210, 60%, 90%)) !important;
                color: var(--portalButtonInfoForeground, hsl(210, 75%, 16%));
                border: 1px solid var(--portalButtonInfoForeground, hsl(210, 75%, 16%));
            }

            paper-button.info-inverted,
            paper-button.info:hover {
                background-color: var(--portalButtonInfoHighlightBackground, hsl(210, 75%, 16%)) !important;
                color: var(--portalButtonInfoHighlightForeground, hsl(210, 60%, 90%)) !important;
            }

            paper-button.info iron-icon {
                stroke: white;
            }

            paper-button.success {
                background-color: var(--portalButtonSuccessBackground, hsl(159, 60%, 90%)) !important;
                color: var(--portalButtonSuccessForeground, hsl(159, 75%, 16%));
                border: 1px solid var(--portalButtonSuccessForeground, hsl(159, 75%, 16%));
            }

            paper-button.success-inverted,
            paper-button.success:hover {
                background-color: var(
                    --portalButtonSuccessHighlightBackground,
                    hsl(159, 75%, 16%)
                ) !important;
                color: var(--portalButtonSuccessHighlightForeground, hsl(159, 60%, 90%));
            }

            paper-button.success iron-icon {
                stroke: white;
            }

            paper-button.warning {
                background-color: var(--portalButtonWarningBackground, hsl(28, 80%, 93%)) !important;
                color: var(--portalButtonWarningForeground, hsl(28, 74%, 24%)) !important;
                border: 1px solid var(--portalButtonWarningForeground, hsl(28, 74%, 24%));
            }

            paper-button.warning-inverted,
            paper-button.warning:hover {
                background-color: var(--portalButtonWarningHighlightBackground, hsl(28, 74%, 24%)) !important;
                color: var(--portalButtonWarningHighlightForeground, hsl(28, 80%, 93%)) !important;
            }

            paper-button.warning iron-icon {
                stroke: white;
            }

            paper-button.danger {
                background-color: var(--portalButtonDangerBackground, hsl(3, 100%, 96%)) !important;
                color: var(--portalButtonDangerForeground, hsl(360, 76%, 20%));
                border: 1px solid var(--portalButtonDangerForeground, hsl(360, 76%, 20%));
            }

            paper-button.danger-inverted,
            paper-button.danger:hover {
                background-color: var(--portalButtonDangerHighlightBackground, hsl(360, 76%, 20%)) !important;
                color: var(--portalButtonDangerHighlightForeground, hsl(3, 100%, 96%)) !important;
            }

            paper-button.danger iron-icon {
                stroke: white;
            }

            paper-button[disabled] {
                opacity: 0.3;
            }

            .loading {
                margin-left: 6px;
                margin-top: 2px;
            }
        `;
    }
    /** @override */ connectedCallback() {
        super.connectedCallback();
        if (this.hovertype && this.hovername) {
            if (!this._boundToggleMouseOver) {
                this._boundToggleMouseOver = this._toggleMouseOver.bind(this);
            }
            this.addEventListener('mouseover', this._boundToggleMouseOver);
            this.addEventListener('mouseout', this._boundToggleMouseOver);
        }
    }
    /** @override */ disconnectedCallback() {
        super.disconnectedCallback();
        if (this.hovertype && this.hovername && this._boundToggleMouseOver) {
            this.removeEventListener('mouseover', this._boundToggleMouseOver);
            this.removeEventListener('mouseout', this._boundToggleMouseOver);
        }
    }
    /** @override */ render() {
        return html `
            <paper-button
                class="${this._getClassName(this.isMouseOverActive, this.loading)}"
                ?disabled="${this._isDisabled(this.loading, this.disabled)}"
                ?raised="${this.raised}"
                ?toggles="${this.toggles}"
                @click="${this._buttonClicked}"
            >
                ${this.icon ? html ` <iron-icon icon="${this.icon}"></iron-icon> ` : html ``}
                ${this._getName(this.isMouseOverActive, this.loading)}
                ${this.loading ? html ` <img class="loading" src="images/ajax-loader.gif" /> ` : html ``}
            </paper-button>
        `;
    }
    setLoading(loading) {
        this.loading = loading;
        this.disabled = loading;
    }
    _buttonClicked(_event) {
        if (this.disabled) {
            return;
        }
        if (this.url) {
            this._openUrl(this.url, this.target);
        }
        else {
            console.warn(this.nodeName + ' har ingen action', this);
        }
    }
    _getClassName(isMouseOverActive, loading) {
        if (loading) {
            return (this.hovertype ? this.hovertype : this.type) || '';
        }
        if (this.hovertype) {
            return (isMouseOverActive ? this.hovertype : this.type) || '';
        }
        return this.type || '';
    }
    _getName(isMouseOverActive, loading) {
        if (loading) {
            return this.hovername ? this.hovername : this.name;
        }
        if (this.hovername) {
            return isMouseOverActive ? this.hovername : this.name;
        }
        return this.name;
    }
    _isDisabled(loading, disabled) {
        this.disabled = loading || disabled;
        return loading || disabled ? true : false;
    }
    _openUrl(url, target) {
        if (target) {
            window.open(url, target);
        }
        else {
            document.location.href = url;
        }
    }
    _toggleMouseOver(_event) {
        this.isMouseOverActive = !this.isMouseOverActive;
    }
};
BoostButton._deps = [];
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostButton.prototype, "action", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostButton.prototype, "disabled", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostButton.prototype, "hovername", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostButton.prototype, "hovertype", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostButton.prototype, "icon", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostButton.prototype, "loading", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostButton.prototype, "name", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostButton.prototype, "raised", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostButton.prototype, "target", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostButton.prototype, "toggles", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostButton.prototype, "type", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostButton.prototype, "url", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostButton.prototype, "isMouseOverActive", void 0);
BoostButton = __decorate([
    customElement(BoostButton.is)
], BoostButton);
export { BoostButton };
