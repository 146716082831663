var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Ajax } from 'badphraim/ajax/Ajax';
import { ServicesBase } from 'base-services/ServicesBase';
import { CerumXState } from './cerumx/CerumXState';
export class CerumServices extends ServicesBase {
    static instance() {
        if (this._instance) {
            return this._instance;
        }
        return (this._instance = new CerumServices());
    }
    static setManifest(m) {
        this.instance()._setManifest(m);
    }
    _getApplicationRoot() {
        const url = super.getApplicationRoot();
        return url.replace(/\/(index.html(\/.*?)?)?(\?.*?)?$/, '');
    }
    *_getParentWindows() {
        let win = window;
        let max = 100;
        do {
            yield win;
            try {
                if (window.opener) {
                    win = window.opener;
                    yield win;
                }
            }
            catch (_error) {
                // denne feiler stort sett på sikkerhet. Ikke noe vi skal klage til noen for
            }
            try {
                if (win.parent === win) {
                    return;
                }
                win = win.parent;
            }
            catch (_error) {
                // denne feiler stort sett på sikkerhet. Ikke noe vi skal klage til noen for
            }
        } while (max-- && win);
    }
    *_getParentDocuments() {
        const windows = this._getParentWindows();
        for (const win of windows) {
            try {
                yield win.document;
            }
            catch (_error) {
                // denne feiler stort sett på sikkerhet. Ikke noe vi skal klage til noen for
            }
        }
    }
    _getLegacyBaseUrl() {
        const docs = this._getParentDocuments();
        for (const doc of docs) {
            try {
                const su = doc.querySelectorAll('link[rel="c:baseurl"]');
                const link = su.item(0);
                if (link) {
                    const href = link.getAttribute('href');
                    if (href) {
                        return href;
                    }
                }
            }
            catch (_error) {
                // denne feiler stort sett på sikkerhet. Ikke noe vi skal klage til noen for
            }
        }
        return '';
    }
    _getLegacyStaticUrl() {
        const docs = this._getParentDocuments();
        for (const doc of docs) {
            try {
                const su = doc.querySelectorAll('link[rel="c:staticurl"]');
                const link = su.item(0);
                if (link) {
                    const href = link.getAttribute('href');
                    if (href) {
                        return href;
                    }
                }
            }
            catch (_error) {
                // denne feiler stort sett på sikkerhet. Ikke noe vi skal klage til noen for
            }
        }
        return '';
    }
    /**  */ getManifestData() {
        const _super = Object.create(null, {
            getManifestData: { get: () => super.getManifestData }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (!CerumXState.isCerumX()) {
                if (this._manifest) {
                    return this._manifest;
                }
                return (this._manifest = this.createLegacyManifest());
            }
            return _super.getManifestData.call(this);
        });
    }
    _getStaticUrl() {
        const _super = Object.create(null, {
            getStaticUrl: { get: () => super.getStaticUrl }
        });
        return __awaiter(this, void 0, void 0, function* () {
            return _super.getStaticUrl.call(this);
        });
    }
    createLegacyManifest() {
        return {
            name: 'Cerum',
            short_name: 'Cerum',
            description: '',
            static_url: this._getLegacyStaticUrl(),
            service_base_url: this._getLegacyBaseUrl(),
        };
    }
    /**  */ getAjaxHandler() {
        // Denne SKAL lastes fra badphraim
        return Ajax.stdInstance();
    }
    /**  */ getBaseUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const baseUrlPromise = this._topCerumServicesInstance()._getBaseUrl();
                // Risking cross-frame-travel. Lets bind it to local evaluation-context
                return new Promise((resolve, reject) => {
                    baseUrlPromise
                        .then((url) => {
                        window.setTimeout(() => {
                            resolve(url);
                        }, 0);
                    })
                        .catch((reason) => {
                        window.setTimeout(() => {
                            reject(reason);
                        });
                    });
                });
            }
            catch (_error) {
                /* void  */
            }
            return this._getBaseUrl();
        });
    }
    /**  */ getApplicationRoot() {
        if (top === window) {
            return this._getApplicationRoot();
        }
        return this._topCerumServicesInstance()._getApplicationRoot();
    }
    /**  */ getStaticUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            return this._topCerumServicesInstance()._getStaticUrl();
        });
    }
    _topCerumServicesInstance() {
        if ('cerum' in window) {
            const topp = window.cerum.top();
            const topp_services = topp.classes.CerumServices;
            return topp_services.instance();
        }
        return CerumServices.instance();
    }
}
