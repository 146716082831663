var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { CalendarOccurenceDeviation } from './CalendarOccurenceDeviation';
import { Delivery } from './Delivery';
import { DeliveryTimeRequirement } from './DeliveryTimeRequirement';
import { WorkplanningAssignee } from './WorkplanningAssignee';
import { WorkplanningRequirement } from './WorkplanningRequirement';
import { WorkplanningTaskClient } from './WorkplanningTaskClient';
import { WorkplanningTaskParent } from './WorkplanningTaskParent';
import { JSONStruct, array_property, nullable, nullable_array_property, property, } from 'badphraim/data/JSONStruct';
export class WorkplanningTask extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.assignees = [];
        /** @override */ this.deviations = [];
        /** @override */ this.exact_time_requirement = false;
        /** @override */ this.id = '';
        /** @override */ this.is_absence = false;
        /** @override */ this.is_static = false;
        /** @override */ this.name = '';
        /** @override */ this.schedule_requirements = [];
    }
}
__decorate([
    nullable,
    __metadata("design:type", WorkplanningAssignee)
], WorkplanningTask.prototype, "assignee", void 0);
__decorate([
    array_property(WorkplanningAssignee),
    __metadata("design:type", Array)
], WorkplanningTask.prototype, "assignees", void 0);
__decorate([
    nullable,
    __metadata("design:type", WorkplanningTaskClient)
], WorkplanningTask.prototype, "client", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], WorkplanningTask.prototype, "completion_status", void 0);
__decorate([
    nullable,
    __metadata("design:type", Delivery)
], WorkplanningTask.prototype, "delivery", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], WorkplanningTask.prototype, "description", void 0);
__decorate([
    array_property(CalendarOccurenceDeviation),
    __metadata("design:type", Array)
], WorkplanningTask.prototype, "deviations", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], WorkplanningTask.prototype, "duration_in_minutes", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], WorkplanningTask.prototype, "exact_time_requirement", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], WorkplanningTask.prototype, "id", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], WorkplanningTask.prototype, "is_absence", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], WorkplanningTask.prototype, "is_static", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], WorkplanningTask.prototype, "name", void 0);
__decorate([
    nullable,
    __metadata("design:type", WorkplanningTaskParent)
], WorkplanningTask.prototype, "parent", void 0);
__decorate([
    array_property(WorkplanningRequirement),
    __metadata("design:type", Array)
], WorkplanningTask.prototype, "schedule_requirements", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], WorkplanningTask.prototype, "start", void 0);
__decorate([
    nullable_array_property(DeliveryTimeRequirement),
    __metadata("design:type", Array)
], WorkplanningTask.prototype, "time_requirements", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], WorkplanningTask.prototype, "time_spent", void 0);
