var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CerumConnect } from 'cerum-services/websockets/CerumConnect';
import { isErrorHTTP, isSuccessHTTP } from 'cerumx-api/APIRestClient';
import { PersistentAPI } from 'cerumx-api/cerum/desktop/persistent/PersistentAPI';
import { CerumReduxSubStore } from './CerumReduxSubStore';
import { AsyncContainer } from 'badphraim/core/AsyncContainer';
export class PersistentSettingsLayout {
    constructor() {
        this.settings = {};
    }
}
export class PersistentSettingsStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'PersistentSettingsStore';
    }
    static get instance() {
        return this._instance || (this._instance = new PersistentSettingsStore());
    }
    /*public static path<T extends Record<K, undefined | null>, K extends keyof T & string>(
        path: keyof T & string
    ) {
        // FIXME det burde være mulig å kreve at denne bare kan brukes dersom key er nullable...
        return this.pathWithDefault<T>(path, undefined as any);
    }*/
    static pathWithDefault(path, defaultValue) {
        const staticPath = this.staticPath('PersistentSettingsStore', 'settings.' + path);
        this.ensureLoadSetting(path, defaultValue);
        this.ensureListening();
        return staticPath;
    }
    static signInStatusReady(loginId) {
        this.readyForListening.setValue(loginId);
    }
    static ensureListening() {
        return __awaiter(this, void 0, void 0, function* () {
            const loginId = yield this.readyForListening.getValue();
            CerumConnect.getGlobalInstance().subscribe(`persistent_settings_${loginId}`, (message) => {
                const messageData = message.data;
                if ('key' in messageData && 'value' in messageData && typeof messageData.key === 'string') {
                    const instance = this.instance;
                    instance.dispatch(PersistentSettingsStore.Actions.loadSettingComplete(messageData.key, messageData.value));
                }
            });
        });
    }
    static ensureLoadSetting(setting, defaultValue) {
        return __awaiter(this, void 0, void 0, function* () {
            let settings = false;
            const instance = this.instance;
            try {
                const state = yield instance.getState();
                settings = setting in state.settings;
            }
            catch (error) {
                console.error(error);
            }
            if (!settings) {
                instance.dispatch(PersistentSettingsStore.Actions.loadSetting(setting, defaultValue));
            }
        });
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new PersistentSettingsLayout(), action) => {
                switch (action.type) {
                    case PersistentSettingsStore.LOAD_SETTING_COMPLETE:
                        if ('key' in action.payload && 'value' in action.payload) {
                            const newState = (state = {
                                settings: Object.assign({}, state.settings),
                            });
                            const persistentKey = String(action.payload.key);
                            const value = action.payload.value;
                            newState.settings[persistentKey] = value;
                            return newState;
                        }
                        break;
                }
                return state;
            },
        };
    }
}
PersistentSettingsStore.readyForListening = new AsyncContainer();
(function (PersistentSettingsStore) {
    PersistentSettingsStore.LOAD_SETTING = 'LOAD_SETTING';
    PersistentSettingsStore.LOAD_SETTING_COMPLETE = 'LOAD_SETTING_COMPLETE';
    PersistentSettingsStore.SAVE_SETTING = 'SAVE_SETTING';
    let Actions;
    (function (Actions) {
        Actions.loadSetting = (persistentKey, defaultValue) => {
            return (dispatch) => __awaiter(this, void 0, void 0, function* () {
                const result = yield PersistentAPI.getSystemPersistentUsingKey(persistentKey);
                if (isErrorHTTP(result)) {
                    return;
                }
                let value;
                if (result.value === undefined || result.value === null) {
                    if (defaultValue !== undefined) {
                        value = defaultValue;
                    }
                    else {
                        value = null;
                    }
                }
                else if (typeof result.value == 'string') {
                    if (result.value.length > 0) {
                        if (result.value[0] == '[' || result.value[0] == '{' || result.value[0] == '"') {
                            try {
                                value = JSON.parse(result.value);
                            }
                            catch (_error) {
                                value = result.value;
                            }
                        }
                        else {
                            value = result.value;
                        }
                    }
                }
                dispatch(Actions.loadSettingComplete(persistentKey, value));
            });
        };
        Actions.loadPortletSettings = (persistentKey, portletId) => __awaiter(this, void 0, void 0, function* () {
            const loginId = yield PersistentSettingsStore.readyForListening.getValue();
            if (!loginId) {
                return;
            }
            const key = loginId + '-portlet-' + portletId + '-' + persistentKey;
            const result = yield PersistentAPI.getSystemPersistentUsingKey(key);
            if (isErrorHTTP(result)) {
                return;
            }
            let value;
            if (result.value === undefined || result.value === null) {
                value = null;
            }
            else if (typeof result.value == 'string') {
                if (result.value.length > 0) {
                    if (result.value[0] == '[' || result.value[0] == '{' || result.value[0] == '"') {
                        try {
                            value = JSON.parse(result.value);
                        }
                        catch (_error) {
                            value = result.value;
                        }
                    }
                    else {
                        value = result.value;
                    }
                }
            }
            return value;
        });
        Actions.loadSettingComplete = (persistentKey, value) => {
            return {
                type: PersistentSettingsStore.LOAD_SETTING_COMPLETE,
                payload: {
                    key: persistentKey,
                    value,
                },
            };
        };
        Actions.savePortletSetting = (persistentKey, value, portletId) => __awaiter(this, void 0, void 0, function* () {
            let writeValue;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (value === null || value === undefined) {
                // WHAT!?!?
                writeValue = null;
            }
            else {
                // Convert all values to json-strings, for more stable decoding
                writeValue = JSON.stringify(value);
            }
            const loginId = yield PersistentSettingsStore.readyForListening.getValue();
            if (!loginId) {
                return;
            }
            const key = loginId + '-portlet-' + portletId + '-' + persistentKey;
            const result = yield PersistentAPI.postSystemPersistentUsingKey(key, {
                value: writeValue,
            });
            if (isErrorHTTP(result)) {
                // SnackBar.pushError(result.error);
            }
        });
        Actions.saveSetting = (persistentKey, value) => {
            return (dispatch) => __awaiter(this, void 0, void 0, function* () {
                let writeValue;
                if (value === undefined || value === null) {
                    // WHAT!?!?
                    writeValue = null;
                }
                else {
                    // Convert all values to json-strings, for more stable decoding
                    writeValue = JSON.stringify(value);
                }
                const result = yield PersistentAPI.postSystemPersistentUsingKey(persistentKey, {
                    value: writeValue,
                });
                if (isSuccessHTTP(result)) {
                    dispatch(Actions.loadSettingComplete(persistentKey, value));
                }
            });
        };
    })(Actions = PersistentSettingsStore.Actions || (PersistentSettingsStore.Actions = {}));
})(PersistentSettingsStore || (PersistentSettingsStore = {}));
